import * as button from "../styles/buttons.module.scss"
import emailjs from "emailjs-com"
import * as React from "react"
import * as styles from "../styles/contact.module.scss"
import { useState, useRef } from "react"

// Ez a komponens tartalmazza a az üzenet küldési lehetőséget

const Contact = () => {
  const form = useRef()
  const [inputs, setInputs] = useState({
    firstname: "",
    lastname: "",
    email: "",
    subject: "",
    message: "",
  })
  const [isWrongEmail, setIsWrongEmail] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [message, setMessage] = useState("")

  const handleMessage = newMessage => {
    // Message for 5 seconds
    if (message === "") {
      setMessage(newMessage)
      setTimeout(function () {
        setMessage("")
      }, 5000)
    }
  }

  const handleChange = event => {
    const name = event.target.name
    const value = event.target.value

    // Input validation
    if (name === "email") {
      let regex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/
      if (!regex.test(value)) {
        setIsWrongEmail(true)
        handleMessage("Hibás adat!")
      } else {
        setIsWrongEmail(false)
      }
    } else {
      let regex = /^[A-ZÖÜÓŐÚÉÁŰÍ].*/
      if (regex.test(value)) {
        setIsValid(true)
      } else {
        setIsValid(false)
        handleMessage("Hibás adat!")
      }
    }

    setInputs(values => {
      return { ...values, [name]: value }
    })
  }

  const handleSubmit = event => {
    event.preventDefault()
    let newInputs = {
      lastName: "",
      firstName: "",
      email: "",
      subject: "",
      message: "",
    }

    // Empty form checking

    if (
      inputs.firstName === "" ||
      inputs.lastName === "" ||
      inputs.email === "" ||
      inputs.subject === "" ||
      inputs.message === ""
    ) {
      handleMessage("Minden mezőt tölts ki!")
    } else {
      emailjs
        .sendForm(
          "service_vc7y7md",
          "template_fe6udbc",
          form.current,
          "user_IpPZvXAjDfF63bHBRpOeJ"
        )
        .then(
          result => {
            // setSentEmail(true)
            // setTimeout(function () {
            //   setSentEmail(false)
            // }, 2000)
            handleMessage("Köszönöm! Válaszolni fogok...")
          },
          error => {
            setMessage(error.text)
          }
        )
    }

    setInputs(newInputs)
  }
  return (
    <section id={styles.contactBar}>
      <div id={styles.contactBarContainer}>
        <div id={styles.left}>
          <h2>Kapcsolat</h2>
          <p>
            Foglalj időpontot vagy kérdezz tőlem! Szívesen válaszolok neked, ha
            kitöltöd az űrlapomat!
          </p>
        </div>

        <div id={styles.right}>
          <form
            id={styles.contactForm}
            type="submit"
            onSubmit={handleSubmit}
            ref={form}
          >
            <div id={styles.name}>
              <input
                placeholder="Vezetéknév"
                style={{ color: isValid ? "green" : "red" }}
                type="text"
                name="lastName"
                value={inputs.lastName}
                onChange={handleChange}
                maxLength="15"
              ></input>
              <input
                placeholder="Keresztnév"
                style={{ color: isValid ? "green" : "red" }}
                type="text"
                name="firstName"
                value={inputs.firstName}
                onChange={handleChange}
                maxLength="15"
              ></input>
            </div>
            <div className={styles.emailSubject}>
              <input
                style={{ color: isWrongEmail ? "red" : "green" }}
                placeholder="email"
                type="email"
                name="email"
                value={inputs.email}
                onChange={handleChange}
                maxLength="20"
              ></input>
              <input
                list="training-subjects"
                placeholder="Tárgy"
                style={{ color: isValid ? "green" : "red" }}
                name="subject"
                value={inputs.subject}
                onChange={handleChange}
                autoComplete="off"
                type="text"
                maxLength="20"
              />
              <datalist id="training-subjects">
                <option value="Időpont foglalás" type="text" />
                <option value="Személyi Edzés" type="text" />
                <option value="Csoportos Fitnesz" type="text" />
                <option value="Online Fitnesz" type="text" />
                <option value="Gyermek Fitnesz" type="text" />
                <option value="Testösszetétel Mérés" type="text" />
              </datalist>
            </div>

            {/* MESSAGES TO THE USER */}
            <div id={styles.userMessage}>{`${message}`}</div>

            <div id={styles.message}>
              <textarea
                placeholder="Üzenet"
                style={{ color: isValid ? "green" : "red" }}
                type="text"
                name="message"
                value={inputs.message}
                onChange={handleChange}
                maxLength="300"
              ></textarea>
            </div>
            <input
              id={styles.submit}
              className={`${button.buttons} ${button.buttonLink2}`}
              type="submit"
              value="Küldés"
            />
          </form>
        </div>
      </div>
    </section>
  )
}

export default Contact
