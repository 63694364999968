import React, { useState } from "react"
import Contact from "../components/contact"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

import * as styles from "../styles/privacy.module.scss"

const Terms = () => {
  const [language, setLanguage] = useState(false)

  return (
    <Layout>
      <Seo title="Szerződési feltételek - Terms and Services" />
      <section id={styles.container}>
        <header id={styles.header}>
          <h1>Szerződési feltételek - Terms and Services</h1>
        </header>

        <button
          id={styles.language}
          style={{ backgroundColor: "grey", margin: "5px" }}
          onClick={() => setLanguage(!language)}
        >
          English/Magyar
        </button>

        <div id={styles.containerInfo}>
          {language === false ? (
            <div>
              <div id={styles.text}>
                These terms and conditions (“Agreement”) set forth the general
                terms and conditions of your use of the www.fittakaroklenni.hu
                website (“Website” or “Service”) and any of its related products
                and services (collectively, “Services”). This Agreement is
                legally binding between you (“User”, “you” or “your”) and this
                Website operator (“Operator”, “we”, “us” or “our”). Please read
                this Agreement carefully before using the Website.
                <br />
                <br />
                <h2>Operator details</h2>
                <ul>
                  <li>Company name: NETALON Rendszerek Kft.</li>
                  <li>Address: 2053 Herceghalom, Búzavirág utca 22.</li>
                </ul>
                <h2> Conditions of use</h2>
                By using this website, you certify that you have read and
                reviewed this Agreement and that you agree to comply with its
                terms. If you do not want to be bound by the terms of this
                Agreement, you are advised to leave the website accordingly.
                Operator only grants use and access of this Website and Services
                to those who have accepted its terms.
                <h2>Privacy policy</h2>
                Before you continue using the Website, we advise you to read our
                <Link className={styles.links} to="/privacy/">
                  privacy policy
                </Link>
                regarding our user data collection. It will help you better
                understand our practices.
                <h2>Intellectual property</h2>
                You agree that all materials, products, and services provided on
                this website are the property of the Operator, including all
                copyrights, trade secrets, trademarks, patents, and other
                intellectual property. You also agree that you will not
                reproduce or redistribute the Operator’s intellectual property
                in any way, including electronic, digital, or new trademark
                registrations.
                <h2>User data</h2>
                As a user of this website you can access information about
                sports and healthy lifestyles services. In some cases We ask you
                to provide some private information in order to acces our
                Calendar function and to contact us. You are responsible for
                ensuring the accuracy of your private information.
                <h2>Links to other resources</h2>
                Although the Website and Services may link to other resources
                (such as websites, mobile applications, etc.). We are not
                responsible for examining or evaluating, and we do not warrant
                the offerings of, any businesses or individuals or the content
                of their resources. We do not assume any responsibility or
                liability for the actions, products, services, and content of
                any other third parties. You should carefully review the legal
                statements and other conditions of use of any resource which you
                access through a link on the Website. Your linking to any other
                off-site resources is at your own risk.
                <h2>Applicable law and disputes</h2>
                By visiting the Website, you agree that any dispute between the
                Operator and You shall be governed by the laws of Hungary.
                <h2>Limitation on liability</h2>
                Operator is not liable for any damages that may occur to you as
                a result of your misuse of our website. Operator reserves the
                right to edit, modify, and change this Agreement any time. When
                we do these changes, we will revise the updated date at the
                bottom of this page. An updated version of this Agreement will
                be effective immediately upon the posting of the revised
                Agreement unless otherwise specified. Your continued use of the
                Website and Services after the effective date of the revised
                Agreement (or such other act specified at that time) will
                constitute your consent to those changes.
                <h2>Contacting us</h2>
                If you have any questions, concerns, or complaints regarding
                this Agreement, we encourage you to contact us using the details
                below:
                <ul>
                  <li> contact person: Gabriella Kovács</li>
                  <li> email us at: kovacsg76@gmail.com</li>
                </ul>
                <h2>Last update</h2>
                This document was last updated on November 22, 2021
              </div>
            </div>
          ) : (
            <div>
              <div id={styles.text}>
                Jelen Szerződés ("Szerződés") a fittakaroklenni.hu weboldal
                ("Weboldal" vagy "Szolgáltatás") és bármely kapcsolódó termék
                vagy szolgáltatás ("Szolgáltatások") használatának általános
                szerződéses feltételeit tartalmazzza. Ez a jogilag kötelező
                érvényű megállapodás az oldal használatával jön létre Ön
                ("Felhasználó vagy "Ön") és a weboldal üzemeltetője
                ("Üzemeltető", "Mi", vagy "Minket") között . Kérjük olvassa át
                alaposan mielőtt az oldalt használni kezdi.
                <br />
                <br />
                <h2>Üzemeltető</h2>
                <ul>
                  <li>Cég neve: NETALON Rendszerek Kft.</li>
                  <li>Címe: 2053 Herceghalom, Búzavirág utca 22.</li>
                </ul>
                <h2> Használati feltételek</h2>A Weboldal használatával Ön
                elismeri, hogy elolvasta és áttekintette a jelen Szerződést és
                Ön elfogadja az abban leírt feltételeket. Ha Ön mégsem akarja
                elfogadni a Szerződésben foglaltakat, azt javasoljuk hagyja el a
                Weboldalt, mert az Üzemeltető csak azoknak engedélyezi a
                Weboldalhoz történő hozzáférést, akik a Szerződés feltételeit
                elfogadják.
                <h2>Adatvédelmi irányelvek</h2>
                Mielőtt folytatná a Weboldal használatát, azt javasoljuk, hogy
                olvassa át az
                <Link className={styles.links} to="/privacy/">
                  adatvédelmi szabályzatunkat
                </Link>
                , amely a Felhasználókkal kapcsolatos adatok gyűjtésére
                vonatkozik. Ez segíthet Önnek megérteni az adatkezelési
                gyakorlatunkat.
                <h2>Szellemi tulajdon</h2>
                Ön elfogadja, hogy minden anyag, termék és szolgáltatás amely
                ezen a weboldalon megjelenik az Üzemeltető tulajdonát képezi,
                beleértve az összes szerzői jogot, üzleti titkot, védjegyet,
                szabadalmat, és egyéb szellemi tulajdont. Ön vállalja, hogy
                semmilyen módon nem reprodukálja vagy terjeszti tovább az
                Üzemeltető szellemi alkotásait, beleértve az elektronikus,
                digitális vagy új védjegybejegyzéseket is.
                <h2>Felhasználói adatok</h2>
                Ön a Weboldal felhasználójaként a sporthoz és egészséges
                életmódhoz kapcsolodó szolgáltatásokról kaphat információkat.
                Bizonyos esetekben, megkérjünk Önt arra, hogy adjon meg néhány
                személyes adatot a Naptár funkcióhoz való hozzáféréshez ill.,
                hogy kapcsolatba léphessen velünk. Ön felelős a személyes
                adatainak pontosságáért.
                <h2>Hivatkozások más forrásokhoz</h2>A Weboldal és a
                Szolgáltatások hivatkozhatnak más forrásokra (például webhelyek,
                mobilalkalmazások stb.). Mi nem vagyunk felelősek ezen források
                vizsgálatért vagy értékelésért és nem vállalunk garanciát az
                ezen forrásokon szereplő ajánlatokért, termékekért,
                szolgáltatásokért, azok tartalmáért. Önnek gondosan át kell
                tekintenie azoknak a webhelyeknek a jogi nyilatkozatait és egyéb
                felhasználási feltételeit, amelyeket Ön elérhet a Weboldalon
                található hivatkozásokon keresztül. A Weboldalon kívül eső
                erőforrásra történő hivatkozás megnyitását Ön a saját
                felelősségére vállalja.
                <h2>Alkalmazandó jog és viták</h2>A Weboldal meglátogatásával Ön
                elfogadja, hogy bármilyen, az Üzemeltető és Ön között felmerülő
                vita megoldására a magyar jogszabályok az irányadók.
                <h2>Felelősség korlátozása</h2>
                Az Üzemeltető nem vállal felelősséget a Weboldallal kapcsolatos
                visszaélésekből eredő károkért. Az Üzemeltető fenntartja a
                jogot, hogy jelen Szerződést bármikor módosítsa vagy
                megváltoztassa. A felülvizsgálat idejét a dokumentum alján
                tüntetjük fel minden esetben. A jelen Szerződés frissített
                változata, ellenkező rendelkezés hiányában a módosítást követően
                azonnal hatályba lép. Ha Ön a módosítást követően is használja a
                Weboldalt, akkor ezzel Ön elfogadja a módosított szerződésben
                foglaltakat.
                <h2>Kapcsolatfelvétel</h2>
                Ha bármilyen kérdése, panasza vagy aggálya van a Szerződésben
                foglaltakkal kapcsolatban, akkor kérjük vegye fel a kapcsolatot
                velünk:
                <ul>
                  <li> kapcsolattartó: Gabriella Kovács</li>
                  <li> email: kovacsg76@gmail.com</li>
                </ul>
                <h2>Utolsó frissítés</h2>A Szerződés utolsó frissítése 2021
                November 22-én történt.
              </div>
            </div>
          )}
        </div>
      </section>
      <Contact />
    </Layout>
  )
}

export default Terms
