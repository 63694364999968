// extracted by mini-css-extract-plugin
export var contactBar = "contact-module--contactBar--3lAgR";
export var contactBarContainer = "contact-module--contactBarContainer--1XrG0";
export var left = "contact-module--left--3KkcY";
export var right = "contact-module--right--3iMDH";
export var contactForm = "contact-module--contactForm--3pdCx";
export var name = "contact-module--name--2qOfA";
export var emailSubject = "contact-module--emailSubject--kQyS1";
export var userMessage = "contact-module--userMessage--ntpg-";
export var message = "contact-module--message--3837E";
export var submit = "contact-module--submit--kaAi9";